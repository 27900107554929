import Papa from 'papaparse';
import './index.css';


var Isotope = require('isotope-layout');
require('isotope-packery');
var matchesSelector = require('matches-selector');

let Url = require('./projekteTH.csv');
var test = require('./pix/*.jpg');



var iso = new Isotope( '.grid', {
  itemSelector: '.element-item',
  layoutMode:  'packery'//'masonry'
});

// filter functions
var filterFns = {
  // show if number is greater than 50
  numberGreaterThan50: function( itemElem ) {
    var number = itemElem.querySelector('.number').textContent;
    return parseInt( number, 10 ) > 50;
  },
  // show if name ends with -ium
  ium: function( itemElem ) {
    var name = itemElem.querySelector('.name').textContent;
    return name.match( /ium$/ );
  }
};

var lang = "DE";
var langsElem = document.querySelector('.langs');
langsElem.addEventListener( 'click', function( event ) {
  // only work with buttons
  var target = event.target;
  lang = target.getAttribute('id');
  if(!target.classList.contains('checked')){
	  document.getElementById("EN").classList.toggle("checked");
	  document.getElementById("DE").classList.toggle("checked"); 
  }  
  var grid = document.getElementById("main");
  grid.innerHTML = '';
  addToArray(data);
});

var filters = [];
var year = [];
var genre = [];
// bind filter button click
var filtersElem = document.querySelector('.filters');
filtersElem.addEventListener( 'click', function( event ) {
  // only work with buttons
  
  var target = event.target;
  var nodes = target.parentElement.children;
  for (var i = 0; i < nodes.length; i++) {
	  if(target.innerHTML != nodes[i].innerHTML){
		if(nodes[i].classList.contains('checked')){
			nodes[i].classList.remove('checked');
			var filter = nodes[i].getAttribute('data-filter');
			removeFilter( filter, getData(filter));
		}
	  }
  }
  target.classList.toggle("checked");
  var isChecked = target.classList.contains('checked');
  var filter = target.getAttribute('data-filter');
  if(filter != null){
	  if ( isChecked ) {
		  addFilter( filter, getData(filter));
	  } else {
		removeFilter( filter, getData(filter));
	  }
	  var yearList = year.join('');
	  var genreList = genre.join('');
	  var filterList = (genreList.trim() + '' + yearList.trim()).trim();
	  iso.arrange({ filter: filterList  });
  }
});

function getData(filter){
	
	if (filter.indexOf(".j2") == 0){
		  return year;
	  }else{
		  return genre;
	  }
	
}

function addFilter( filter, data ) {
  if ( data.indexOf( filter ) == -1 ) {
    data.push( filter );
  }
}
function removeFilter( filter, data ) {
  var index = data.indexOf( filter);
  if ( index != -1 ) {
    data.splice( index, 1 );
  }
}



function PostDataRedirect(data, dataName, location) {
var form = document.createElement("form");

form.method = "GET";
form.action = location;

if (data.constructor === Array && dataName.constructor === Array) {
    for (var i = 0; i < data.length; i++) {
        var element = document.createElement("input");
        element.type = "hidden";
        element.value = data[i];
		element.id = dataName[i];
        element.name = dataName[i];
        form.appendChild(element);
    }
} else {
    var element1 = document.createElement("input");
    element1.type = "hidden";
    element1.value = data;
	element1.id = dataName;
    element1.name = dataName;
    form.appendChild(element1);
}
//window.open('http://localhost:1234/detail.html', "_blank", "", false);
document.body.appendChild(form);

form.submit(); 
}



function addToArray(array) {
    var grid = document.getElementById("main");
    for (var i = 0; i < array.length; i++) {
		if(array[i]["Datum"]){
			var shortText = array[i]["Short" + lang];
			var size = array[i]["Bildgroesse"].split(",");
			var genre = array[i]["Genre"];
			var jahr = array[i]["Datum"];
			var shortDiv = document.createElement('div');
			shortDiv.className = "short";
			shortDiv.innerHTML = shortText;
			var div = document.createElement('div');
			var roundjahr = (Math.floor((jahr-1)/5)+1)*5;
			div.className = "element-item " + size[0].trim() + " j" + roundjahr + 
			" "  + genre;
			div.setAttribute("data-category","post-transition");
			div.id = i;
			
			var img = document.createElement('img');
			img.className = "grid-item image";
			var pic = array[i]["PIX"].split(",")[0].trim().split(".")[0];
			img.src = test[pic];
			
			var cell = document.createElement('h3');
			cell.className = "name";
			cell.innerHTML = array[i]["Titel"];
			
			
			
			
			div.appendChild(img);
			div.appendChild(shortDiv);
			div.appendChild(cell);
			
			div.addEventListener("click", function() {
				PostDataRedirect(this.id, "ID", "detail.html");	
			  
			});		
							
			grid.appendChild(div);
			iso.appended( div );
			
			iso.layout();
		}
    }
	
	
	iso.layout();
    
}

function makeTable(array) {
    var table = document.createElement('table');
    for (var i = 0; i < array.length; i++) {
        var row = document.createElement('tr');
		var cell = document.createElement('td');
		cell.textContent = array[i]["Beschreibung"];
		row.appendChild(cell);
		cell = document.createElement('td');
		cell.textContent = array[i]["Beschreibung"];
		row.appendChild(cell);
        
        table.appendChild(row);
    }
    return table;
}


var data;
Papa.parse(Url, {
	download: true,
	quotes: true, //or array of booleans
	quoteChar: '"',
	escapeChar: '"',
	delimiter: ";",
	header: true,
	encoding: 'ansi',
	newline: "\r\n",
	skipEmptyLines: false,
	complete: function(results) {
		console.log("Finished:", results);
		data = results.data;		
		//document.body.appendChild(makeTable(data));
		addToArray(data);
		iso.arrange({ filter: '.j2025' });
	}
});